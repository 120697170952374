<template>
  <v-form>
    <v-card>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-btn
              color="warning"
              @click="$router.push({name: 'admin:candidates'})"
            >
              <v-icon left>
                undo
              </v-icon>
              Volver
            </v-btn>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="firstname"
              type="text"
              name="firstname"
              autocomplete="given-name"
              prepend-icon="account_box"
              :error-messages="fnameErrors"
              label="Nombre"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="lastname"
              type="text"
              name="lastname"
              autocomplete="family-name"
              :error-messages="lnameErrors"
              label="Apellido"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="Fecha de nacimiento"
                  prepend-icon="event"
                  readonly
                  :autocomplete="false"
                  :error-messages="dateErrors"
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="menu2 = false"
              />
            </v-menu>
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="email"
              type="email"
              name="email"
              autocomplete="email"
              prepend-icon="mail_outline"
              :error-messages="emailErrors"
              label="Email"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <CountryPicker
              v-model="country"
              prepend-icon="public"
              label="Nacionalidad"
              :autocomplete="false"
              :only-available="false"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <CountryPicker
              v-model="countryLive"
              prepend-icon="public"
              label="País de Residencia"
              :autocomplete="false"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <RegionPicker
              :key="countryLive"
              v-model="region"
              :country="countryLive"
              label="Región"
              prepend-icon="map"
              :autocomplete="false"
              :error-messages="regioncityErrors"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <CityPicker
              :key="region"
              v-model="city"
              :region="region"
              label="Ciudad o Comuna"
              :autocomplete="false"
              :error-messages="regioncityErrors"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <v-text-field
              v-model="phone"
              label="Teléfono"
              prepend-icon="phone"
              type="tel"
              name="phone"
              :prefix="numberPrefix"
              autocomplete="phone"
              :error-messages="phoneErrors"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="address"
              label="Dirección"
              prepend-icon="place"
              type="text"
              name="street-line-1"
              autocomplete="address-line1"
              :error-messages="addressErrors"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="idnumber"
              label="Número de identificación"
              prepend-icon="picture_in_picture"
              type="text"
              :autocomplete="false"
              :error-messages="idnumberErrors"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <v-select
              v-model="genero"
              :items="itemsG"
              item-text="text"
              item-value="value"
              prepend-icon="wc"
              label="Género"
              return-object
              :autocomplete="false"
              :error-messages="generoErrors"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <StudiesPicker
              v-model="studies"
              prepend-icon="school"
              :country="countryLive"
              label="Nivel de Estudios"
              :error-messages="studiesErrors"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <v-text-field
              v-model="cvName"
              label="Archivo CV"
              prepend-icon="attach_file"
              :autocomplete="false"
              readonly
              :error-messages="cvErrors"
              @click="pickFile"
            />
            <input
              ref="image"
              type="file"
              style="display: none"
              accept=".pdf, .doc, .docx"
              @change="onFilePicked"
            >
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="lastCompany"
              label="Última empresa"
              prepend-icon="domain"
              :autocomplete="false"
              type="text"
              :error-messages="companyErrors"
            />
          </v-flex>
          <v-flex
            xs3
            ml-3
          >
            <v-text-field
              v-model="lastPosition"
              label="Último puesto"
              prepend-icon="person_outline"
              :autocomplete="false"
              type="text"
              :error-messages="positionErrors"
            />
          </v-flex>
        </v-layout>

        <v-chip
          v-model="errorchip"
          close
          color="red"
          text-color="white"
        >
          {{ chipmsg }}
        </v-chip>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          type="submit"
          @click.prevent="authenticate"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios';

import StudiesPicker from '@/components/inputs/StudiesPicker.vue';

import CountryPicker from '@/components/inputs/CountryPicker.vue';
import RegionPicker from '@/components/inputs/RegionPicker.vue';
import CityPicker from '@/components/inputs/CityPicker.vue';

export default {

  name: 'CandidateSignup',

  components: {
    CountryPicker,
    RegionPicker,
    CityPicker,
    StudiesPicker,
  },
  data() {
    return {
      errorchip: false,
      chipmsg: '',
      submitStatus: 'PENDING',
      firstname: '',
      lastname: '',
      email: '',
      menu2: false,
      date: '',
      country: 'CL',
      countryLive: 'CL',
      region: '',
      city: '',
      phone: '',
      address: '',
      genero: '',
      studies: 0,
      idnumber: '',
      loading: false,
      itemsG: [
        { text: 'Masculino', value: 1 },
        { text: 'Femenino', value: 2 },

      ],
      cvName: '',
      cvFile: null,
      lastCompany: '',
      lastPosition: '',
      respErrors: null,
    };
  },
  computed: {

    numberPrefix() {
      if (this.countryLive === 'CL') {
        return '+56';
      } if (this.countryLive === 'PE') {
        return '+51';
      } if (this.countryLive === 'CO') {
        return '+57';
      }
      return '';
    },

    jointNumber() {
      return `${this.numberPrefix}${this.phone}`;
    },

    fnameErrors() {
      return (this.respErrors === null ? [] : this.respErrors.first_name);
    },

    lnameErrors() {
      return (this.respErrors === null ? [] : this.respErrors.last_name);
    },

    emailErrors() {
      return (this.respErrors === null ? [] : this.respErrors.email);
    },

    passwordErrors() {
      return (this.respErrors === null ? [] : this.respErrors.password);
    },

    passwordConfErrors() {
      return (this.respErrors === null ? [] : this.respErrors.password_conf);
    },

    dateErrors() {
      return (this.respErrors === null ? [] : this.respErrors.birth_date);
    },

    regioncityErrors() {
      return (this.respErrors === null ? [] : this.respErrors.city);
    },

    phoneErrors() {
      return (this.respErrors === null ? [] : this.respErrors.phone_number);
    },

    addressErrors() {
      return (this.respErrors === null ? [] : this.respErrors.address);
    },

    generoErrors() {
      return (this.respErrors === null ? [] : this.respErrors.gender);
    },

    studiesErrors() {
      return (this.respErrors === null ? [] : this.respErrors.studies);
    },

    idnumberErrors() {
      return (this.respErrors === null ? [] : this.respErrors.national_id);
    },

    companyErrors() {
      return (this.respErrors === null ? [] : this.respErrors.last_company);
    },

    positionErrors() {
      return (this.respErrors === null ? [] : this.respErrors.last_position);
    },

    pfpErrors() {
      return (this.respErrors === null ? [] : this.respErrors.profile_pic);
    },

    cvErrors() {
      return (this.respErrors === null ? [] : this.respErrors.cv);
    },

    password() {
      /**
       * a random password generator since we have to create an user anyways
       */
      const result = [];
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 8; i += 1) {
        result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
      }
      return result.join('');
    },
  },
  watch: {
    menu2(val) {
      if (val) {
        setTimeout(() => { this.$refs.picker.activePicker = 'YEAR'; });
      }
    },
  },

  methods: {

    authenticate() {
      this.$store.commit('setLoading');
      const form = new FormData();
      form.append('username', this.email);
      form.append('password', this.password);
      form.append('password_conf', this.password);
      form.append('first_name', this.firstname);
      form.append('last_name', this.lastname);
      form.append('email', this.email);
      form.append('birth_date', this.date);
      form.append('nationality', this.country);
      form.append('city', this.city);
      form.append('phone_number', this.jointNumber);
      form.append('address', this.address);
      form.append('gender', this.genero.value);
      form.append('national_id', this.idnumber);
      form.append('last_company', this.lastCompany);
      form.append('last_position', this.lastPosition);
      form.append('cv', this.cvFile);
      form.append('studies', this.studies);

      const headers = {
        'Content-Type': 'multipart/form-data; boundary = &',
      };

      axios.post(this.$store.state.endpoints.candidates.register, form, { headers })
        .then(() => {
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Candidato registrado exitosamente.');
          this.$router.push({ name: 'admin:candidates' });
        })
        .catch(e => {
          this.errorchip = true;
          if (e.response.data.non_field_errors) {
            this.chipmsg = e.response.data.non_field_errors.pop();
          } else {
            this.respErrors = e.response.data;
            this.chipmsg = 'Por favor verifique los datos ingresados en el formulario.';
          }
          this.$store.commit('unsetLoading');
        });
    },

    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      this.cvFile = e.target.files[0] !== undefined ? e.target.files[0] : null;
      this.cvName = e.target.files[0] !== undefined ? e.target.files[0].name : '';
    },
  },
};
</script>
