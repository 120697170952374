<template>
  <v-autocomplete
    v-model="pickerValue"
    :items="itemsS"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
    :clearable="clearable"
    :disabled="!editable"
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'StudiesPicker',
  props: {
    country: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      loading: false,
      itemsS: [],
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
    country() {
      this.getForCountry(this.country);
    },
  },
  mounted() {
    this.getForCountry(this.country);
    this.$nextTick(() => {
      this.pickerValue = this.value;
    });
  },
  methods: {
    getForCountry(country) {
      axios.get(`${this.$store.state.endpoints.candidates.studies}?country=${country}`)
        .then(resp => {
          this.itemsS = Object.entries(resp.data).map(a => ({ text: a[0], value: a[1] }));
        });
    },
  },
};
</script>
