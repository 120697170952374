<template>
  <v-autocomplete
    v-model="pickerValue"
    :disabled="loading || cities.length < 1 || !editable"
    :loading="loading"
    :items="cities"
    item-value="uuid"
    item-text="name"
    v-bind="$attrs"
    :clearable="clearable"
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'CityPicker',
  props: {
    region: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      cities: [],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadCities();
    });
  },
  methods: {
    loadCities() {
      this.loading = true;
      const endpoint = this.$store.state.endpoints.offers.public.cities;
      axios.get(endpoint, { params: { region: this.region } })
        .then(response => {
          this.cities = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
