<template>
  <v-autocomplete
    v-model="pickerValue"
    :disabled="loading || !editable"
    :loading="loading"
    height="58"
    :items="items"
    v-bind="$attrs"
    :clearable="clearable"
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'CountryPicker',
  props: {
    onlyAvailable: {
      type: Boolean,
      default: true,
    },
    defaultToChile: {
      type: Boolean,
      default: true,
    },
    defaultToCountry: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      countries: [],
      loading: false,
    };
  },
  computed: {
    items() {
      return this.countries;
    },
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCountries();
      this.pickerValue = this.value;
    });
  },
  methods: {
    loadCountries() {
      this.countries = [];
      this.loading = true;
      let promise = null;
      if (this.onlyAvailable) {
        promise = axios.get(this.$store.state.endpoints.offers.public.countries,
          { params: { only_available: this.onlyAvailable } });
      } else {
        promise = axios.get(this.$store.state.endpoints.offers.public.countries);
      }

      promise.then(response => {
        this.countries = response.data.map(data => ({
          value: data[0],
          text: data[1],
        }));
        this.loading = false;
        if (this.defaultToCountry
            && this.countries.map(data => data.value).indexOf(this.defaultToCountry) >= 0) {
          this.pickerValue = this.defaultToCountry;
        } else if (this.defaultToChile) {
          this.pickerValue = 'CL';
        }
      });
    },
  },
};
</script>
